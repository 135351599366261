.pane-oa-home-default .modules .block {
  border-radius: 0;
  width: 80%;
}

@media (min-width: 768px) {
  .pane-oa-home-default .modules .block {
    width: 50%;
  }
}

.pane-oa-home-default .modules .block h2 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.pane-oa-home-default .modules .entry-placeholder {
  display: none;
}

.pane-oa-home-default #block-user-login {
  margin-top: 20%;
  border-style: none;
  min-height: 0;
  padding-bottom: 20px;
  margin-left: 10%;
  margin-right: 10%;
}

@media (min-width: 768px) {
  .pane-oa-home-default #block-user-login {
    margin-left: 25%;
    margin-right: 25%;
  }
}

.pane-oa-home-default #block-user-login h2 {
  color: #fff;
  text-transform: none;
  font-weight: bold;
  font-size: 28px;
  padding: 30px 15px;
  letter-spacing: 3px;
}

// Mixins
// -----------------------------------------------------------------------------

// Clearfix - DO NOT REMOVE
// Includes the has-layout part from compass without the overflow hidden
// and the pseudo classes from bootstrap, otherwise all drop menus get clipped.
@mixin clearfix {
  @include has-layout;
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

@mixin loginhdr() {
  color: #fff;
  text-transform: none;
  font-weight: bold;
  font-size: 28px;
  padding: 30px 15px;
  letter-spacing: 3px;
}

// Vertically align an element
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// modal background styles
@mixin modal-bg() {
  opacity: 0.65 !important;
  background-color: #000 !important;
}
